import styled, { css } from "styled-components";
import Img, { FluidObject } from "gatsby-image";

import { device } from "../../../../../../../utils/breakpoints";
import { fadeIn, fadeOut } from "../../../../../../../utils/animations";
import {
  H2,
  H3,
  SUBTITLE_2,
  TEXT_BODY_2,
} from "../../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 80px 48px 32px 48px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;

  @media ${device.computer} {
    min-height: 100vh;
    padding: 80px 120px 80px 120px;
    display: grid;
    grid-template-columns: repeat(12, 64px);
    grid-column-gap: 32px;
  }

  @media ${device.desktop} {
    padding: 128px 120px 120px 120px;
    grid-template-columns: repeat(12, 110px);
  } ;
`;

export const LogoWrapper = styled.div`
  width: 100%;

  @media ${device.computer} {
    margin-bottom: 32px;
  }

  @media ${device.desktop} {
    margin-bottom: 0;
  } ;
`;

export const RowWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column-reverse;

  @media ${device.computer} {
    flex-direction: row;
    align-items: center;
    grid-column: 1 / 12;
  } ;
`;

export const MobileViewWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 24px 0;
  padding: 0 96px;

  @media ${device.tabletLandscape} {
    padding: 0 212px;
  }

  @media ${device.computer} {
    margin: 0;
    padding: 0 32px;
    grid-column: 6 / 11;
    width: 456px;
  }

  @media ${device.laptopL} {
    width: 900px;
  } ;
`;

export const MobileView = styled(Img)<{
  fluid: FluidObject | FluidObject[];
}>`
  width: 100%;
  height: 100%;

  img {
    object-fit: contain !important;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;

  @media ${device.computer} {
    width: 681px;
    height: auto;
    grid-column: 1/4;
  } ;
`;

export const Title = styled.div`
  margin: 0;
  font-size: 32px;
  line-height: 38px;
  color: #013cff;
  text-align: left;
  font-weight: 700;

  a,
  a:visited,
  a:link {
    color: #013cff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  @media ${device.computer} {
    ${H3}
    grid-column: 1 / 4;
  }

  @media ${device.desktop} {
    ${H2}
  } ;
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  width: 80%;
  color: #151515;
  white-space: pre-line;

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin: 32px 0;
  }

  @media ${device.computer} {
    margin: 32px 0 64px 0;
  } ;
`;

export const DescriptionTitle = styled.span`
  ${SUBTITLE_2}
`;

export const DownloadIconsWrapper = styled.div`
  margin: 0;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  * {
    width: 10rem;
    height: auto;
    margin-right: 24px;
    box-sizing: unset;

    &:hover {
      cursor: pointer;
    }
  }
`;
