import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

import { 
  PageWrapper,
  LogoWrapper,
  RowWrapper,
  MobileViewWrapper,
  MobileView,
  ContentWrapper,
  Title,
  Description,
  DescriptionTitle,
  DownloadIconsWrapper
} from "./style";
import { PageChange } from "../../../../../../common/Scroll";
import { fixConjunctions } from "../../../../../../../utils/dropTheWord";
import VileoLogo from "-!svg-react-loader!../../../../../../../assets/images/vileo/logo.svg";
import DownlaodAppStore from "-!svg-react-loader!../../../../../../../assets/images/downloadAppStore.svg";
import DownloadGooglePlay from "-!svg-react-loader!../../../../../../../assets/images/downloadGooglePlay.svg";

interface VileoProps {
  pageChange: PageChange;
};

const VileoPage: React.FC<VileoProps> = ({ pageChange }) => {
  const { t } = useTranslation("rnd");
  const data = useStaticQuery(imageQuery);

  return (
    <PageWrapper animation={pageChange}>
      <LogoWrapper>
        <VileoLogo />
      </LogoWrapper>
      <RowWrapper>
        <ContentWrapper>
            <Title>{t(`vileo_title`)}</Title>
            <Description>
              <DescriptionTitle>{t(`vileo_descriptions_title`)}</DescriptionTitle>
              {t(`vileo_descriptions`)}
            </Description>
            <DownloadIconsWrapper>
              <a href="https://apps.apple.com/pl/app/vileo/id1518538329?l=pl"
                target="_blank" 
                rel="noopener noreferrer"
              >
              <DownlaodAppStore />
              </a>
              <a 
                href="https://play.google.com/store/apps/details?id=com.oke.vileo" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <DownloadGooglePlay />
              </a>
            </DownloadIconsWrapper>
          </ContentWrapper>
          <MobileViewWrapper>
            <MobileView fluid={data.file && data.file.childImageSharp.fluid} />
          </MobileViewWrapper>
      </RowWrapper>
    </PageWrapper>
  );
};

const imageQuery = graphql`
{
  file(name: { eq: "vileoMobileView" }) {
    name
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
}
`;

export default VileoPage;