import styled, { keyframes, css } from "styled-components";
import Img, { FluidObject } from "gatsby-image";

import { device } from "../../../../../../utils/breakpoints";
import {
  fadeIn,
  fadeOut,
  slideFromTop,
} from "../../../../../../utils/animations";
import {
  H2,
  H3,
  TEXT_BODY_2,
} from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

const squareDimension = "8vw";

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

export const Banner = styled.div`
  position: relative;
  width: 70%;
  height: 36vh;
  transform: translateY(-100%);
  animation: ${slideFromTop} 0.6s ease-out;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;

  @media ${device.tablet}, ${device.tabletLandscape} {
    width: 100%;
  }

  @media ${device.computer} {
    width: 100%;
    height: 60vh;
  }

  @media ${device.laptopL} {
    width: 70%;
    height: 36vh;
  } ;
`;

export const BannerBackground = styled(Img)<{
  fluid: FluidObject | FluidObject[];
}>`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const BannerLogo = styled(Img)<{
  fluid: FluidObject | FluidObject[];
}>`
  position: absolute !important;
  top: 50%;
  left: calc(50% - 16px);
  width: 50%;
  height: 40%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: ${fadeIn} 0.6s linear;
  animation-delay: 1s;
  animation-fill-mode: forwards;

  img {
    object-fit: contain !important;
  }

  @media ${device.computer} {
    width: 294px;
    height: 88px;
  }

  @media ${device.desktop} {
    width: 322px;
    height: 96px;
  } ;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  width: calc(66% - ${squareDimension});
  display: flex;
  flex-direction: column;
  opacity: 0;
  animation: ${fadeIn} 0.6s linear;
  animation-delay: 1.6s;
  animation-fill-mode: forwards;

  @media ${device.tablet}, ${device.tabletLandscape} {
    width: 100%;
    padding-right: 80px;
    animation-delay: 1s;
  }

  @media ${device.computer} {
    width: 49%;
  }

  @media ${device.desktop} {
    width: calc(66% - ${squareDimension});
  } ;
`;

export const DescriptionWrapper = styled.div`
  padding: 74px 88px;
  display: grid;
  grid-template-columns: calc(50% - 24px) 48px calc(50% - 24px);
  grid-template-rows: 40px 40px auto;

  @media ${device.tablet}, ${device.tabletLandscape} {
    display: flex;
    flex-direction: column;
  }

  @media ${device.computer} {
    padding: 112px 120px 80px 120px;
    display: flex;
    flex-direction: column;
  }

  @media ${device.desktop} {
    padding: 64px 120px 120px 120px;
    display: grid;
    grid-template-columns: calc(50% - 24px) 48px calc(50% - 24px);
    grid-template-rows: 40px 40px auto;
  } ;
`;

export const Title = styled.div`
  margin: 0;
  grid-column-end: span 3;
  color: #013cff;
  font-size: 30px;
  line-height: 36px;
  padding-bottom: 24px;
  font-weight: 700;

  @media ${device.computer} {
    ${H3}
    padding-bottom: 0;
  }

  @media ${device.desktop} {
    ${H2}
  } ;
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  grid-row: 3;
  color: #151515;
  white-space: pre-line;

  a,
  a:visited,
  a:link {
    color: #013cff;
    text-decoration: none;

    @media ${device.tablet}, ${device.tabletLandscape} {
      font-weight: 600;
    }
  }

  a:hover {
    text-decoration: underline;
  }

  &:last-of-type {
    grid-column: 3;
  }

  @media ${device.computer} {
    margin-top: 32px;
  }

  @media ${device.desktop} {
    margin-top: 48px;
  } ;
`;

export const SideWrapper = styled.div`
  position: absolute;
  top: calc(36vh - ${squareDimension});
  right: 0;
  width: calc(30% + ${squareDimension});
  min-height: calc(64vh + ${squareDimension});
  padding: 0 132px 0 ${squareDimension};
  display: grid;
  grid-template-rows: ${squareDimension} auto;

  @media ${device.tablet}, ${device.tabletLandscape} {
    position: relative;
    top: 0;
    width: 100%;
    min-height: 0;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  @media ${device.computer} {
    top: 60vh;
    width: 51%;
    height: calc(100vh + ${squareDimension});
    padding: 112px 220px 0 120px;
  }

  @media ${device.laptopL} {
    top: calc(36vh - ${squareDimension});
    min-height: calc(56vh + ${squareDimension});
    padding: 0 132px 0 ${squareDimension};
  }

  @media ${device.desktop} {
    width: calc(30% + ${squareDimension});
    padding: 0 200px 120px ${squareDimension};
  }
`;

const slideFromTopWithHeightChange = keyframes`
  0% {
    height: 0;
  };

  100% {
    height: 100%;
  };
`;

export const SideTransitionWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 0;
  background: #013cff;
  animation: ${slideFromTopWithHeightChange} 0.6s linear;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  z-index: -1;

  @media ${device.tablet}, ${device.tabletLandscape} {
    animation-delay: 1.6s;
  } ;
`;

export const WhiteSquare = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(${squareDimension});
  height: calc(${squareDimension});
  z-index: 1;
  background: #ffffff;

  @media ${device.tablet}, ${device.tabletLandscape} {
    display: none;
  }

  @media ${device.computer} {
    display: none;
  }

  @media ${device.laptop} {
    width: ${squareDimension};
    height: ${squareDimension};
  }

  @media ${device.desktop} {
    display: block;
  }
`;

export const SideContent = styled.div`
  grid-row-start: 2;
  padding: 24px 0;

  @media ${device.tablet}, ${device.tabletLandscape} {
    padding: 56px 160px 56px 88px;
  }

  @media ${device.computer} {
    grid-row-start: 1;
    padding: 0;
  }

  @media ${device.desktop} {
    grid-row-start: 2;
    padding: 64.5px 0;
  } ;
`;

export const SideTitle = styled.div`
  ${H3}
  margin: 0;
  color: #ffffff;
  padding-bottom: 20px;
  white-space: pre-line;

  @media ${device.computer} {
    padding-bottom: 24px;
  }

  @media ${device.desktop} {
    padding-bottom: 48px;
  } ;
`;

export const SideDescription = styled.div`
  ${TEXT_BODY_2}
  color: #FFFFFF;
  white-space: pre-line;

  @media ${device.tablet}, ${device.tabletLandscape} {
    width: 412px;

    &:first-of-type {
      margin-left: 0;
      margin-right: auto;
      margin-bottom: 40px;
    }

    &:last-of-type {
      margin-left: auto;
      margin-right: 0;
    }
  } ;
`;
