import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  Banner,
  BannerBackground,
  BannerLogo,
  WhiteSquare,
  ContentWrapper,
  DescriptionWrapper,
  Title,
  Description,
  SideWrapper,
  SideTransitionWrapper,
  SideContent,
  SideTitle,
  SideDescription,
} from "./style";
import VileoApp from "../Vileo/Desktop";
import { PageChange } from "../../../../../common/Scroll";
import { PageThemeType } from "../../../../../../utils/types";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import { PageTitle } from "../../../../../common/PageTitle";

interface CivileoProps {
  pageChange: PageChange;
  navPageThemeChange?: (theme?: PageThemeType) => void;
  scrollPageThemeChange?: (theme?: PageThemeType) => void;
}

const CivileoPage: React.FC<CivileoProps> = ({
  pageChange,
  navPageThemeChange,
  scrollPageThemeChange,
}) => {
  const { t } = useTranslation("rnd");
  const { logo, background } = useStaticQuery(imagesQuery);
  const descriptions = t(`civileo_descriptions`) as unknown as string[];

  useEffect(() => {
    if (isTablet) {
      document.body.addEventListener("scroll", handleScroll, { passive: true });
      if (document.body.scrollTop <= 0) {
        setTimeout(() => {
          navPageThemeChange && navPageThemeChange("blue");
          scrollPageThemeChange && scrollPageThemeChange("white");
        });
      }

      return () => {
        document.body.removeEventListener("scroll", handleScroll);
        navPageThemeChange && navPageThemeChange(undefined);
        scrollPageThemeChange && scrollPageThemeChange(undefined);
      };
    }
  }, [isTablet]);

  const handleScroll = () => {
    const banner = document
      .getElementById("civileoBanner")
      ?.getBoundingClientRect();
    const page = document
      .getElementById("civileoBlueWrapper")
      ?.getBoundingClientRect();
    const firstPageIndicator = document.getElementsByClassName(
      "pageIndicator"
    )[0];
    const navPadding = 88;
    const scrollPadding = firstPageIndicator?.getBoundingClientRect().top;
    if (banner && Math.abs(banner.y - navPadding) < banner.height) {
      navPageThemeChange && navPageThemeChange("blue");
    } else if (
      page &&
      page.y < navPadding &&
      Math.abs(page.y - navPadding) < page.height
    ) {
      navPageThemeChange && navPageThemeChange("blue");
    } else {
      navPageThemeChange && navPageThemeChange(undefined);
    }

    if (
      page &&
      page.y < scrollPadding &&
      page.height + page.y > scrollPadding
    ) {
      scrollPageThemeChange && scrollPageThemeChange("blue");
    } else {
      scrollPageThemeChange && scrollPageThemeChange("white");
    }
  };

  return (
    <>
      <PageTitle subtitle={t(`civileo_page_title`)} />
      <PageWrapper>
        <Banner id="civileoBanner">
          {background && (
            <BannerBackground fluid={background.childImageSharp.fluid} />
          )}
          {logo && <BannerLogo fluid={logo.childImageSharp.fluid} />}
        </Banner>

        <ContentWrapper>
          <DescriptionWrapper>
            <Title color="#013CFF">{t(`civileo_title`)}</Title>
            <Description
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(descriptions[0]),
              }}
            />
            <Description
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(descriptions[1]),
              }}
            />
          </DescriptionWrapper>
        </ContentWrapper>

        <SideWrapper id="civileoBlueWrapper">
          <SideTransitionWrapper />
          <WhiteSquare>
            <div />
          </WhiteSquare>
          <SideContent>
            <SideTitle
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(t(`civileo_sideTitle`)),
              }}
            />
            {(t(`civileo_sideDescriptions`) as unknown as string[]).map(
              (desc: string, i: number) => (
                <SideDescription
                  key={i}
                  dangerouslySetInnerHTML={{
                    __html: fixConjunctions(desc),
                  }}
                />
              )
            )}
          </SideContent>
        </SideWrapper>
      </PageWrapper>
      <VileoApp pageChange={pageChange} />
    </>
  );
};

const imagesQuery = graphql`
  {
    logo: file(name: { eq: "logo" }, relativeDirectory: { eq: "civileo" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    background: file(
      name: { eq: "logoBackground" }
      relativeDirectory: { eq: "civileo" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

export default CivileoPage;
